import axios from '@axios'
import common from '@/libs/common'
import defaultService from './_default.service'

export default function generalDataService() {
  const {
    throwError,
    throwSuccess,
    // toastSuccess,
    // toastError,
  } = common()
  const API_URL = `${process.env.VUE_APP_ADUACORE_API_URL}/api`
  const defaultMethods = defaultService('RecursosHumanos')
  // ===================================================================|
  //  Employee----------------------------------------------------------------------------------------------------|
  // ===================================================================|
  const fetchEmployee = (data, callback) => {
    axios
      .get(`${API_URL}/RecursosHumanos/Empleado/${data}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const updateEmployee = data => new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/RecursosHumanos/Empleado/${data.nombreUsuario}`, data)
      .then(response => {
        resolve()
        throwSuccess(response)
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  // ===================================================================|
  //  Work Area----------------------------------------------------------------------------------------------------|
  // ===================================================================|
  const fetchWorkAreas = (data, callback) => {
    axios
      .get(`${API_URL}/RecursosHumanos/AreasTrabajo`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchWorkArea = (data, callback) => {
    axios
      .get(`${API_URL}/RecursosHumanos/AreasTrabajo/${data.areaTrabajoId}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const createWorkArea = data => new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/RecursosHumanos/AreasTrabajo`, data)
      .then(response => {
        throwSuccess(response)
        resolve()
      })
      .catch(error => {
        reject(error)
        throwError(error)
      })
  })
  const updateWorkArea = data => new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/RecursosHumanos/AreasTrabajo/${data.areaTrabajoId}`, data)
      .then(response => {
        resolve()
        throwSuccess(response)
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  const deleteWorkArea = (data, callback) => {
    axios
      .delete(`${API_URL}/RecursosHumanos/AreasTrabajo/${data}`)
      .then(response => {
        throwSuccess(response)
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  // ===================================================================|
  //  Departament-------------------------------------------------------------------------------------------------|
  // ===================================================================|
  const fetchDepartaments = (data, callback) => {
    axios
      .get(`${API_URL}/RecursosHumanos/Departamento`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchDepartament = (data, callback) => {
    axios
      .get(`${API_URL}/RecursosHumanos/Departamento/${data.departamentoId}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const createDepartament = data => new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/RecursosHumanos/Departamento`, data)
      .then(response => {
        throwSuccess(response)
        resolve()
      })
      .catch(error => {
        reject(error)
        throwError(error)
      })
  })
  const updateDepartament = data => new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/RecursosHumanos/Departamento/${data.departamentoId}`, data)
      .then(response => {
        resolve()
        throwSuccess(response)
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  const deleteDepartament = (data, callback) => {
    axios
      .delete(`${API_URL}/RecursosHumanos/Departamento/${data}`)
      .then(response => {
        throwSuccess(response)
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchDepartamentsOptions = callback => {
    axios
      .get(`${API_URL}/Opciones/Departamentos`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchEmployesOptions = callback => {
    axios
      .get(`${API_URL}/Opciones/Empleados`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  // ===================================================================|
  //  Scholarship-------------------------------------------------------------------------------------------------|
  // ===================================================================|
  const fetchScholarships = (data, callback) => {
    axios
      .get(`${API_URL}/RecursosHumanos/Escolaridad`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchScholarship = (data, callback) => {
    axios
      .get(`${API_URL}/RecursosHumanos/Escolaridad/${data.escolaridadId}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const createScholarship = data => new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/RecursosHumanos/Escolaridad`, data)
      .then(response => {
        throwSuccess(response)
        resolve()
      })
      .catch(error => {
        reject(error)
        throwError(error)
      })
  })
  const updateScholarship = data => new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/RecursosHumanos/Escolaridad/${data.escolaridadId}`, data)
      .then(response => {
        resolve()
        throwSuccess(response)
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  const deleteScholarship = (data, callback) => {
    axios
      .delete(`${API_URL}/RecursosHumanos/Escolaridad/${data}`)
      .then(response => {
        throwSuccess(response)
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  // ===================================================================|
  //  CivilStatus-------------------------------------------------------------------------------------------------|
  // ===================================================================|
  const fetchCivilStatus = (data, callback) => {
    axios
      .get(`${API_URL}/RecursosHumanos/EstadoCivil`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchCivilStatusId = (data, callback) => {
    axios
      .get(`${API_URL}/RecursosHumanos/EstadoCivil/${data.estadoCivilId}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const createCivilStatus = data => new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/RecursosHumanos/EstadoCivil`, data)
      .then(response => {
        throwSuccess(response)
        resolve()
      })
      .catch(error => {
        reject(error)
        throwError(error)
      })
  })
  const updateCivilStatus = data => new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/RecursosHumanos/EstadoCivil/${data.estadoCivilId}`, data)
      .then(response => {
        resolve()
        throwSuccess(response)
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  const deleteCivilStatus = (data, callback) => {
    axios
      .delete(`${API_URL}/RecursosHumanos/EstadoCivil/${data}`)
      .then(response => {
        throwSuccess(response)
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  // ===================================================================|
  //  WorkPlace-------------------------------------------------------------------------------------------------|
  // ===================================================================|
  const fetchWorkPlaces = (data, callback) => {
    axios
      .get(`${API_URL}/RecursosHumanos/Sucursal`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchWorkPlace = (data, callback) => {
    axios
      .get(`${API_URL}/RecursosHumanos/Sucursal/${data.sucursalId}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const createWorkPlace = data => new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/RecursosHumanos/Sucursal`, data)
      .then(response => {
        throwSuccess(response)
        resolve()
      })
      .catch(error => {
        reject(error)
        throwError(error)
      })
  })
  const updateWorkPlace = data => new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/RecursosHumanos/Sucursal/${data.sucursalId}`, data)
      .then(response => {
        resolve()
        throwSuccess(response)
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  const deleteWorkPlace = (data, callback) => {
    axios
      .delete(`${API_URL}/RecursosHumanos/Sucursal/${data}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  // ===================================================================|
  //  WorkStation-------------------------------------------------------------------------------------------------|
  // ===================================================================|
  const fetchWorkStations = (data, callback) => {
    axios
      .get(`${API_URL}/RecursosHumanos/PuestoTrabajo`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchWorkStation = (data, callback) => {
    axios
      .get(`${API_URL}/RecursosHumanos/PuestoTrabajo/${data.puestoTrabajoId}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const createWorkStation = data => new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/RecursosHumanos/PuestoTrabajo`, data)
      .then(response => {
        throwSuccess(response)
        resolve()
      })
      .catch(error => {
        reject(error)
        throwError(error)
      })
  })
  const updateWorkStation = data => new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/RecursosHumanos/PuestoTrabajo/${data.puestoTrabajoId}`, data)
      .then(response => {
        resolve()
        throwSuccess(response)
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  const deleteWorkStation = (data, callback) => {
    axios
      .delete(`${API_URL}/RecursosHumanos/PuestoTrabajo/${data}`)
      .then(response => {
        throwSuccess(response)
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchWorkAreaOptions = callback => {
    axios
      .get(`${API_URL}/Opciones/AreasTrabajo`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchScholarshipOptions = callback => {
    axios
      .get(`${API_URL}/Opciones/Escolaridad`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  // ===================================================================|
  //  EmployeeType-------------------------------------------------------------------------------------------------|
  // ===================================================================|
  const fetchEmployeeTypes = (data, callback) => {
    axios
      .get(`${API_URL}/RecursosHumanos/TipoEmpleado`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchEmployeeType = (data, callback) => {
    axios
      .get(`${API_URL}/RecursosHumanos/TipoEmpleado/${data.tipoEmpleadoId}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const createEmployeeType = data => new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/RecursosHumanos/TipoEmpleado`, data)
      .then(response => {
        throwSuccess(response)
        resolve()
      })
      .catch(error => {
        reject(error)
        throwError(error)
      })
  })
  const updateEmployeeType = data => new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/RecursosHumanos/TipoEmpleado/${data.tipoEmpleadoId}`, data)
      .then(response => {
        resolve()
        throwSuccess(response)
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  const deleteEmployeeType = (data, callback) => {
    axios
      .delete(`${API_URL}/RecursosHumanos/TipoEmpleado/${data}`)
      .then(response => {
        throwSuccess(response)
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  // ===================================================================|
  //  Staff request -------------------------------------------------|
  // ===================================================================|
  const fetchStaffRequest = (requisicionPersonalId, callback) => defaultMethods.fetch('RequsicionPersonal/%{requisicionPersonalId}', { requisicionPersonalId }, {}, callback)
  const fetchStaffRequests = (data, callback) => defaultMethods.fetch('RequsicionPersonal', { }, data, callback)
  const createStaffRequest = (data, callback) => defaultMethods.create('RequsicionPersonal', {}, true, data, callback)
  const updateStaffRequest = (requisicionPersonalId, data, callback) => defaultMethods.update('RequisicionPersonal/%{requisicionPersonalId}', { requisicionPersonalId }, true, data, callback)
  const removeStaffRequest = (requisicionPersonalId, callback) => defaultMethods.remove('RequisicionPersonal/%{requisicionPersonalId}', { requisicionPersonalId }, true, callback)
  const createStaffRequestExcelFile = (elementoId, data, callback) => defaultMethods.downloadFileByPost('Elementos/%{elementoId}/TabularExcel', { elementoId }, data, callback)

  // ===================================================================|
  //  Hability -------------------------------------------------|
  // ===================================================================|
  const fetchHabilities = (data, callback) => defaultMethods.fetch('Habilidad', {}, data, callback)
  const fetchHability = (id, callback) => defaultMethods.fetch('Habilidad/%{id}', { id }, {}, callback)
  const createHability = (data, callback) => defaultMethods.create('Habilidad', {}, true, data, callback)
  const updateHability = (id, data, callback) => defaultMethods.update('Habilidad/%{id}', { id }, true, data, callback)
  const deleteHability = (id, callback) => defaultMethods.remove('Habilidad/%{id}', { id }, true, callback)

  // ===================================================================|
  //  TypeHability -------------------------------------------------|
  // ===================================================================|
  const fetchHabilityTypes = (data, callback) => defaultMethods.fetch('HabilidadTipo', {}, data, callback)
  const fetchHabilityType = (id, callback) => defaultMethods.fetch('HabilidadTipo/%{id}', { id }, {}, callback)
  const createHabilityType = (data, callback) => defaultMethods.create('HabilidadTipo', {}, true, data, callback)
  const updateHabilityType = (id, data, callback) => defaultMethods.update('HabilidadTipo/%{id}', { id }, true, data, callback)
  const deleteHabilityType = (id, callback) => defaultMethods.remove('HabilidadTipo/%{id}', { id }, true, callback)

  // ===================================================================|
  //  ClassContract -------------------------------------------------|
  // ===================================================================|
  const fetchClassContracts = (data, callback) => defaultMethods.fetch('ClaseContrato', {}, data, callback)
  const fetchClassContract = (id, callback) => defaultMethods.fetch('ClaseContrato/%{id}', { id }, {}, callback)
  const createClassContract = (data, callback) => defaultMethods.create('ClaseContrato', {}, true, data, callback)
  const updateClassContract = (id, data, callback) => defaultMethods.update('ClaseContrato/%{id}', { id }, true, data, callback)
  const deleteClassContract = (id, callback) => defaultMethods.remove('ClaseContrato/%{id}', { id }, true, callback)

  // ===================================================================|
  //  LaboralDedication-------------------------------------------------|
  // ===================================================================|
  const fetchLaboralDedications = (data, callback) => defaultMethods.fetch('DedicacionLaboral', {}, data, callback)
  const fetchLaboralDedication = (id, callback) => defaultMethods.fetch('DedicacionLaboral/%{id}', { id }, {}, callback)
  const createLaboralDedication = (data, callback) => defaultMethods.create('DedicacionLaboral', {}, true, data, callback)
  const updateLaboralDedication = (id, data, callback) => defaultMethods.update('DedicacionLaboral/%{id}', { id }, true, data, callback)
  const deleteLaboralDedication = (id, callback) => defaultMethods.remove('DedicacionLaboral/%{id}', { id }, true, callback)

  // ===================================================================|
  //  PaymentType-------------------------------------------------|
  // ===================================================================|
  const fetchSchemeTypePayments = (data, callback) => defaultMethods.fetch('EsquemaPagoTipo', {}, data, callback)
  const fetchSchemeTypePayment = (id, callback) => defaultMethods.fetch('EsquemaPagoTipo/%{id}', { id }, {}, callback)
  const createSchemeTypePayment = (data, callback) => defaultMethods.create('EsquemaPagoTipo', {}, true, data, callback)
  const updateSchemeTypePayment = (id, data, callback) => defaultMethods.update('EsquemaPagoTipo/%{id}', { id }, true, data, callback)
  const deleteSchemeTypePayment = (id, callback) => defaultMethods.remove('EsquemaPagoTipo/%{id}', { id }, true, callback)

  // ===================================================================|
  //  PaymentType-------------------------------------------------|
  // ===================================================================|
  const fetchAcademicsTraining = (data, callback) => defaultMethods.fetch('FormacionAcademica', {}, data, callback)
  const fetchAcademicTraining = (id, callback) => defaultMethods.fetch('FormacionAcademica/%{id}', { id }, {}, callback)
  const createAcademicTraining = (data, callback) => defaultMethods.create('FormacionAcademica', {}, true, data, callback)
  const updateAcademicTraining = (id, data, callback) => defaultMethods.update('FormacionAcademica/%{id}', { id }, true, data, callback)
  const deleteAcademicTraining = (id, callback) => defaultMethods.remove('FormacionAcademica/%{id}', { id }, true, callback)

  // ===================================================================|
  //  LaboralFunction-------------------------------------------------|
  // ===================================================================|
  const fetchLaboralFunctions = (data, callback) => defaultMethods.fetch('FuncionLaboral', {}, data, callback)
  const fetchLaboralFunction = (id, callback) => defaultMethods.fetch('FuncionLaboral/%{id}', { id }, {}, callback)
  const createLaboralFunction = (data, callback) => defaultMethods.create('FuncionLaboral', {}, true, data, callback)
  const updateLaboralFunction = (id, data, callback) => defaultMethods.update('FuncionLaboral/%{id}', { id }, true, data, callback)
  const deleteLaboralFunction = (id, callback) => defaultMethods.remove('FuncionLaboral/%{id}', { id }, true, callback)

  // ===================================================================|
  //  Language-------------------------------------------------|
  // ===================================================================|
  const fetchLanguages = (data, callback) => defaultMethods.fetch('Idioma', {}, data, callback)
  const fetchLanguage = (id, callback) => defaultMethods.fetch('Idioma/%{id}', { id }, {}, callback)
  const createLanguage = (data, callback) => defaultMethods.create('Idioma', {}, true, data, callback)
  const updateLanguage = (id, data, callback) => defaultMethods.update('Idioma/%{id}', { id }, true, data, callback)
  const deleteLanguage = (id, callback) => defaultMethods.remove('Idioma/%{id}', { id }, true, callback)

  // ===================================================================|
  //  Schedule-------------------------------------------------|
  // ===================================================================|
  const fetchSchedules = (data, callback) => defaultMethods.fetch('Horario', {}, data, callback)
  const fetchSchedule = (id, callback) => defaultMethods.fetch('Horario/%{id}', { id }, {}, callback)
  const createSchedule = (data, callback) => defaultMethods.create('Horario', {}, true, data, callback)
  const updateSchedule = (id, data, callback) => defaultMethods.update('Horario/%{id}', { id }, true, data, callback)
  const deleteSchedule = (id, callback) => defaultMethods.remove('Horario/%{id}', { id }, true, callback)

  // ===================================================================|
  //  Profile-------------------------------------------------|
  // ===================================================================|
  const fetchProfiles = (data, callback) => defaultMethods.fetch('Perfil', {}, data, callback)
  const fetchProfile = (id, callback) => defaultMethods.fetch('Perfil/%{id}', { id }, {}, callback)
  const createProfile = (data, callback) => defaultMethods.create('Perfil', {}, true, data, callback)
  const updateProfile = (id, data, callback) => defaultMethods.update('Perfil/%{id}', { id }, true, data, callback)
  const deleteProfile = (id, callback) => defaultMethods.remove('Perfil/%{id}', { id }, true, callback)

  // ===================================================================|
  //  ProfileAcademicTraining-------------------------------------------------|
  // ===================================================================|
  const fetchProfilesAcademicTraining = (data, callback) => defaultMethods.fetch('PerfilFormacionAcademica', {}, data, callback)
  const fetchProfileAcademicTraining = (id, callback) => defaultMethods.fetch('PerfilFormacionAcademica/%{id}', { id }, {}, callback)
  const createProfileAcademicTraining = (data, callback) => defaultMethods.create('PerfilFormacionAcademica', {}, true, data, callback)
  const updateProfileAcademicTraining = (id, data, callback) => defaultMethods.update('PerfilFormacionAcademica/%{id}', { id }, true, data, callback)
  const deleteProfileAcademicTraining = (id, callback) => defaultMethods.remove('PerfilFormacionAcademica/%{id}', { id }, true, callback)

  // ===================================================================|
  //  ProfileHability-------------------------------------------------|
  // ===================================================================|
  const fetchProfilesHability = (data, callback) => defaultMethods.fetch('PerfilHabilidad', {}, data, callback)
  const fetchProfileHability = (id, callback) => defaultMethods.fetch('PerfilHabilidad/%{id}', { id }, {}, callback)
  const createProfileHability = (data, callback) => defaultMethods.create('PerfilHabilidad', {}, true, data, callback)
  const updateProfileHability = (id, data, callback) => defaultMethods.update('PerfilHabilidad/%{id}', { id }, true, data, callback)
  const deleteProfileHability = (id, callback) => defaultMethods.remove('PerfilHabilidad/%{id}', { id }, true, callback)

  // ===================================================================|
  //  ProfileLanguage-------------------------------------------------|
  // ===================================================================|
  const fetchProfilesLanguage = (data, callback) => defaultMethods.fetch('PerfilIdioma', {}, data, callback)
  const fetchProfileLanguage = (id, callback) => defaultMethods.fetch('PerfilIdioma/%{id}', { id }, {}, callback)
  const createProfileLanguage = (data, callback) => defaultMethods.create('PerfilIdioma', {}, true, data, callback)
  const updateProfileLanguage = (id, data, callback) => defaultMethods.update('PerfilIdioma/%{id}', { id }, true, data, callback)
  const deleteProfileLanguage = (id, callback) => defaultMethods.remove('PerfilIdioma/%{id}', { id }, true, callback)

  // ===================================================================|
  //  ProfileSalary-------------------------------------------------|
  // ===================================================================|
  const fetchProfilesSalary = (data, callback) => defaultMethods.fetch('PerfilSalarial', {}, data, callback)
  const fetchProfileSalary = (id, callback) => defaultMethods.fetch('PerfilSalarial/%{id}', { id }, {}, callback)
  const createProfileSalary = (data, callback) => defaultMethods.create('PerfilSalarial', {}, true, data, callback)
  const updateProfileSalary = (id, data, callback) => defaultMethods.update('PerfilSalarial/%{id}', { id }, true, data, callback)
  const deleteProfileSalary = (id, callback) => defaultMethods.remove('PerfilSalarial/%{id}', { id }, true, callback)

  // ===================================================================|
  //  PaymentTypePeriod-------------------------------------------------|
  // ===================================================================|
  const fetchPaymentTypesPeriod = (data, callback) => defaultMethods.fetch('PeriodoPagoTipo', {}, data, callback)
  const fetchPaymentTypePeriod = (id, callback) => defaultMethods.fetch('PeriodoPagoTipo/%{id}', { id }, {}, callback)
  const createPaymentTypePeriod = (data, callback) => defaultMethods.create('PeriodoPagoTipo', {}, true, data, callback)
  const updatePaymentTypePeriod = (id, data, callback) => defaultMethods.update('PeriodoPagoTipo/%{id}', { id }, true, data, callback)
  const deletePaymentTypePeriod = (id, callback) => defaultMethods.remove('PeriodoPagoTipo/%{id}', { id }, true, callback)

  // ===================================================================|
  //  Jobs-------------------------------------------------|
  // ===================================================================|
  const fetchJobs = (data, callback) => defaultMethods.fetch('Puesto', {}, data, callback)
  const fetchJob = (id, callback) => defaultMethods.fetch('Puesto/%{id}', { id }, {}, callback)
  const createJob = (data, callback) => defaultMethods.create('Puesto', {}, true, data, callback)
  const updateJob = (id, data, callback) => defaultMethods.update('Puesto/%{id}', { id }, true, data, callback)
  const deleteJob = (id, callback) => defaultMethods.remove('Puesto/%{id}', { id }, true, callback)

  // ===================================================================|
  //  JobFunctions-------------------------------------------------|
  // ===================================================================|
  const fetchJobLaboralFunctions = (data, callback) => defaultMethods.fetch('PuestoFuncionLaboral', {}, data, callback)
  const fetchJobLaboralFunction = (id, callback) => defaultMethods.fetch('PuestoFuncionLaboral/%{id}', { id }, {}, callback)
  const createJobLaboralFunction = (data, callback) => defaultMethods.create('PuestoFuncionLaboral', {}, true, data, callback)
  const updateJobLaboralFunction = (id, data, callback) => defaultMethods.update('PuestoFuncionLaboral/%{id}', { id }, true, data, callback)
  const deleteJobLaboralFunction = (id, callback) => defaultMethods.remove('PuestoFuncionLaboral/%{id}', { id }, true, callback)

  // ===================================================================|
  //  JobSchedules-------------------------------------------------|
  // ===================================================================|
  const fetchJobSchedules = (data, callback) => defaultMethods.fetch('PuestoHorario', {}, data, callback)
  const fetchJobSchedule = (id, callback) => defaultMethods.fetch('PuestoHorario/%{id}', { id }, {}, callback)
  const createJobSchedule = (data, callback) => defaultMethods.create('PuestoHorario', {}, true, data, callback)
  const updateJobSchedule = (id, data, callback) => defaultMethods.update('PuestoHorario/%{id}', { id }, true, data, callback)
  const deleteJobSchedule = (id, callback) => defaultMethods.remove('PuestoHorario/%{id}', { id }, true, callback)

  // ===================================================================|
  //  JobLinePositions-------------------------------------------------|
  // ===================================================================|
  const fetchJobLinePositions = (data, callback) => defaultMethods.fetch('PuestoLineaAscenso', {}, data, callback)
  const fetchJobLinePosition = (id, callback) => defaultMethods.fetch('PuestoLineaAscenso/%{id}', { id }, {}, callback)
  const createJobLinePosition = (data, callback) => defaultMethods.create('PuestoLineaAscenso', {}, true, data, callback)
  const updateJobLinePosition = (id, data, callback) => defaultMethods.update('PuestoLineaAscenso/%{id}', { id }, true, data, callback)
  const deleteJobLinePosition = (id, callback) => defaultMethods.remove('PuestoLineaAscenso/%{id}', { id }, true, callback)

  return {
    // Employee
    fetchEmployee,
    updateEmployee,
    // Work Areas
    fetchWorkAreas,
    fetchWorkArea,
    createWorkArea,
    updateWorkArea,
    deleteWorkArea,
    // Departament
    fetchDepartaments,
    fetchDepartament,
    createDepartament,
    updateDepartament,
    deleteDepartament,
    fetchDepartamentsOptions,
    fetchEmployesOptions,
    // Scholarship
    fetchScholarships,
    fetchScholarship,
    createScholarship,
    updateScholarship,
    deleteScholarship,
    // CivilStatus
    fetchCivilStatus,
    fetchCivilStatusId,
    createCivilStatus,
    updateCivilStatus,
    deleteCivilStatus,
    // WorkPlace
    fetchWorkPlaces,
    fetchWorkPlace,
    createWorkPlace,
    updateWorkPlace,
    deleteWorkPlace,
    // WorkStation
    fetchWorkStations,
    fetchWorkStation,
    createWorkStation,
    updateWorkStation,
    deleteWorkStation,
    fetchWorkAreaOptions,
    fetchScholarshipOptions,
    // EmployeeType
    fetchEmployeeTypes,
    fetchEmployeeType,
    createEmployeeType,
    updateEmployeeType,
    deleteEmployeeType,
    // Personal request
    fetchStaffRequest,
    fetchStaffRequests,
    createStaffRequest,
    updateStaffRequest,
    removeStaffRequest,
    createStaffRequestExcelFile,
    // Hability
    fetchHabilities,
    fetchHability,
    createHability,
    updateHability,
    deleteHability,
    // Hability type
    fetchHabilityTypes,
    fetchHabilityType,
    createHabilityType,
    updateHabilityType,
    deleteHabilityType,
    // ClassContract
    fetchClassContracts,
    fetchClassContract,
    createClassContract,
    updateClassContract,
    deleteClassContract,
    // LabporalDedication
    fetchLaboralDedications,
    fetchLaboralDedication,
    createLaboralDedication,
    updateLaboralDedication,
    deleteLaboralDedication,
    // SchemeTypePayment
    fetchSchemeTypePayments,
    fetchSchemeTypePayment,
    createSchemeTypePayment,
    updateSchemeTypePayment,
    deleteSchemeTypePayment,
    // AcademicTraining
    fetchAcademicsTraining,
    fetchAcademicTraining,
    createAcademicTraining,
    updateAcademicTraining,
    deleteAcademicTraining,
    // LaboralFunction
    fetchLaboralFunctions,
    fetchLaboralFunction,
    createLaboralFunction,
    updateLaboralFunction,
    deleteLaboralFunction,
    // Languages
    fetchLanguages,
    fetchLanguage,
    createLanguage,
    updateLanguage,
    deleteLanguage,
    // Schedule
    fetchSchedules,
    fetchSchedule,
    createSchedule,
    updateSchedule,
    deleteSchedule,
    // Profile
    fetchProfiles,
    fetchProfile,
    createProfile,
    updateProfile,
    deleteProfile,
    // ProfileAcademicTraining
    fetchProfilesAcademicTraining,
    fetchProfileAcademicTraining,
    createProfileAcademicTraining,
    updateProfileAcademicTraining,
    deleteProfileAcademicTraining,
    // Profile Hability
    fetchProfilesHability,
    fetchProfileHability,
    createProfileHability,
    updateProfileHability,
    deleteProfileHability,
    // Profile Language
    fetchProfilesLanguage,
    fetchProfileLanguage,
    createProfileLanguage,
    updateProfileLanguage,
    deleteProfileLanguage,
    // Profile Salary
    fetchProfilesSalary,
    fetchProfileSalary,
    createProfileSalary,
    updateProfileSalary,
    deleteProfileSalary,
    // PaymentTypePeriod
    fetchPaymentTypesPeriod,
    fetchPaymentTypePeriod,
    createPaymentTypePeriod,
    updatePaymentTypePeriod,
    deletePaymentTypePeriod,
    // Jobs
    fetchJobs,
    fetchJob,
    createJob,
    updateJob,
    deleteJob,
    // JobLaboralFunction
    fetchJobLaboralFunctions,
    fetchJobLaboralFunction,
    createJobLaboralFunction,
    updateJobLaboralFunction,
    deleteJobLaboralFunction,
    // JobSchedule
    fetchJobSchedules,
    fetchJobSchedule,
    createJobSchedule,
    updateJobSchedule,
    deleteJobSchedule,
    // JobLinePosition
    fetchJobLinePositions,
    fetchJobLinePosition,
    createJobLinePosition,
    updateJobLinePosition,
    deleteJobLinePosition,
  }
}
