<template>
  <div>
    <civil-status-add-new
      :is-add-new-civil-status-sidebar-active.sync="isAddNewCivilStatusSidebarActive"
      @refresh-data="() => { refCivilStatusList.refreshData() }"
    />
    <list
      ref="refCivilStatusList"
      sort-by="estadoCivilId"
      :refetch-records="fetchCivilStatus"
      refetch-records-name="estadosCiviles"
      :actions="actions"
      key-field="estadoCivilId"
      :table-columns="tableColumns"
      :table-actions="tableActions"
      :delete-method="deleteCivilStatus"
      :filters.sync="filters"
    />
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import List from '@/components/List.vue'
import humanResources from '@/services/humanresources.service'
import CivilStatusAddNew from './CivilStatusAddNew.vue'

export default {
  components: {
    List,
    CivilStatusAddNew,
  },
  setup() {
    /* Refs */
    const refCivilStatusList = ref(null)
    const isAddNewCivilStatusSidebarActive = ref(false)
    /* Services */
    const { fetchCivilStatus, deleteCivilStatus, fetchCivilStatusId } = humanResources()
    /* Table columns */
    const tableColumns = [
      {
        label: i18n.t('humanResources.civilStatus.columns.civilStatusColumn'),
        key: 'nombre',
      },
      {
        label: i18n.t('humanResources.civilStatus.columns.descriptionColumn'),
        key: 'descripcion',
      },
      {
        label: i18n.t('Lists.Actions'),
        key: 'actions',
      },
    ]
    const tableActions = ref([
      {
        name: 'edit',
        label: i18n.t('Lists.Edit'),
        aclAction: 'create',
        aclResource: 'EstadoCivil',
        routeName: 'apps-human-resources-civil-status-edit',
        params: ['estadoCivilId'],
        icon: 'CodepenIcon',
      },
      {
        name: 'delete',
        label: i18n.t('Lists.Delete'),
        aclAction: 'delete',
        aclResource: 'EstadoCivil',
        params: ['estadoCivilId'],
        // disabledCondition: item => item.estadoCivilId === 3,
        icon: 'TrashIcon',
      },
    ])
    const actions = ref([
      {
        label: i18n.t('humanResources.civilStatus.list.newCivilStatusAction'),
        aclAction: 'create',
        click: () => { isAddNewCivilStatusSidebarActive.value = true },
        icon: 'PlusCircleIcon',
      },
    ])
    const filters = ref([])
    return {
      // Refs & data
      refCivilStatusList,
      tableColumns,
      tableActions,
      actions,
      filters,
      isAddNewCivilStatusSidebarActive,
      // API Calls
      fetchCivilStatus,
      fetchCivilStatusId,
      deleteCivilStatus,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
